<template>
  <div>
    <div v-if="isshowgrid">
      <va-card title="Food List">  
        <div class="row">
          <div class="flex md6 mt-2">
            <va-input class="va-input-search-container"
              :value="term"
              placeholder="search"
              @input="search"
              removable
            >
              <va-icon name="fa fa-search" slot="prepend" />
            </va-input>
          </div>
          <div class="flex md6 mt-2 text-right">
            <va-button
              icon="fa fa-plus"
              class="my-0"
              @click.prevent="add()">
              Create
            </va-button>
          </div>
        </div>
        <va-data-table
          :fields="fields"
          :data="filteredData"
          :per-page="parseInt(perPage)"
          style="max-height: 90vh;">
          <template slot="actions" slot-scope="props">
            <va-button
              flat
              small
              color="gray"
              icon="fa fa-pencil"
              @click="edit(props.rowData)"
              class="ma-0">
            </va-button>
          </template>
          <template slot="action" slot-scope="props">
            <va-button
              flat
              small
              color="red"
              icon="fa fa-remove"
              @click="remove(props.rowData)"
              class="ma-0">
            </va-button>
          </template>

        </va-data-table>
        <va-modal
          v-model="showRemoveModal"
          title="Delete Food"
          size='small'
          :message="msg"
          okText="Confirm"
          cancelText="Cancel"
          @ok="deleteFood(entity)"
          @cancel="cancel()">
        </va-modal>
      </va-card>
    </div>
    <div v-if="isshowForm">
      <va-card :title="title">
        <template slot="actions">
          <va-button color="gray" @click="list()">List</va-button>
        </template>
        <div>
          <form>
            <div class="mt-1">
              <span class="va-form-label va-form-required-label">Food Name</span>
              <va-input
                v-model="food_name"
                type="text"
                v-if="isCreate"
                placeholder="Enter Food Name"
                @input="searchfood()"
                :error="!!FoodNameErrors.length"
                :error-messages="FoodNameErrors"
              />
              <div class="results" v-if="listOpen">
                <ul>
                  <li v-for="name in foodNameArr" @click="onClick(name)">
                    <span>{{name.name}}</span>
                  </li>
                </ul>
              </div>
              <va-input
                v-if="isUpdate"
                type="text"
                v-model.trim="food_name"
              />
              <span class="va-form-label va-form-required-label">Description</span>
              <va-input
                v-model.trim="description"
                type="text"
                v-if="isCreate || isUpdate"
                placeholder="Enter Your Description"
                :error="!!DescriptionErrors.length"
                :error-messages="DescriptionErrors"
              />
              <span class="va-form-label va-form-required-label">Category</span>
              <multiselect
                v-if="isCreate || isUpdate"
                v-model="category"
                placeholder="Select Food Category"
                :close-on-select="false"
                :clear-on-select="false"  
                :preserve-search="true"
                :hide-selected="false"
                :taggable="false"
                :multiple="true"
                :options="categoryArr"
                label="category"
                track-by="category"
              />
              <div v-if="CategoryErrors.length > 0"  class="errors">{{CategoryErrors[0]}}</div>
              <span class="va-form-label va-form-required-label">Type</span>
              <va-select
                v-if="isCreate || isUpdate"
                placeholder="Select Type"
                v-model="type"
                textBy="type"
                searchable
                :options="typeArr"
                :error="!!TypeErrors.length"
                :error-messages="TypeErrors"
              />
              <span class="va-form-label va-form-required-label">Price</span>
              <va-input
                v-if="isCreate || isUpdate"
                v-model.trim="price"
                type="number"
                placeholder="Enter Your Price"
                :error="!!PriceErrors.length"
                :error-messages="PriceErrors"
              />
              <div>
                <span v-if="isCreate" class="va-form-label va-form-required-label">Image (500 X 500)</span>
                <span v-if="isUpdate" class="va-form-label">Image (500 X 500)</span>
                <va-file-upload
                  v-if="isUpdate || isCreate"
                  type="single"
                  class="mt-2 ml-1"
                  @input="CheckFile('horizontal')"
                  v-model="image_file"
                />
                <div v-if="isUpdate">
                  <div class="scroll_overflow">
                    <div class="ml-1 mt-2" v-if="oldImage">
                      <div><img :src=image_url height="250px" width="250px" /></div>                 
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="(isCreate && !getImage || isUpdate) && imageFile"
                class="imagePreviewWrapper mt-1 ml-2"
                :style="{ 'background-image': `url(${previewImage})` }">
              </div>
              <div v-if="isCreate && getImage" class=" ml-1 mt-4"><img :src=image_url height="250px" width="250px" /></div>
              <div class="mt-2">
                <span class="va-form-label">Upload Video</span>
                <div>
                  <input
                    type="file"
                    style="display: none;"
                    ref="food_video"
                    accept=".mp4"
                    id="food_video"
                    class="mt-2"
                    v-on:change="validateVideo($event,'food')"
                  />              
                </div>
                <va-button
                  type="submit"
                  class="mt-2 ml-1"
                  @click.prevent="$refs.food_video.click()">
                  Upload Video
                </va-button>
                <label v-if="(isCreate || isUpdate) && !getVideo" class="ml-4">{{ foodVideoName }}</label>
                <label v-if="(isCreate || isUpdate) && getVideo" class="ml-4">{{ video_dis_name }}</label>
              </div>
              <div class="ml-2">
                <video 
                  v-if="(isCreate || isUpdate) && !getVideo" 
                  width="520" 
                  height="440" 
                  style="display: none; margin-top: -10px;" 
                  controls 
                  autoplay 
                />
                <video 
                  id="myVideo" 
                  width="520" 
                  height="440" 
                  v-if="(isCreate || isUpdate) && getVideo" 
                  style="margin-top: -10px;" 
                  autoplay 
                  controls
                  :src=videoUrl 
                />
              </div>
              <div class="mt-3 ml-1">
                <va-checkbox
                  v-model="menu_of_the_day"
                  label="Menu of the day"
                />
              </div>
              <div class="d-flex justify--end mt-3">
                <va-button type="submit" class="my-0 va-button-normal-cancel" @click="list()">Cancel</va-button>
                <va-button v-if="isCreate" type="submit" class="my-0" @click.prevent="createFood()">Create</va-button>
                <va-button v-if="isUpdate" type="submit" class="my-0" @click.prevent="updateFood()">Update</va-button>
              </div>
            </div>
          </form>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>

  import Vue from 'vue'
  import vueResource from 'vue-resource'
  import { search } from '../../i18n/search.js'
  import config from '../../i18n/en.json'
  import REGEX from '../../i18n/regex_pattern'
  import { debounce } from 'lodash'
  Vue.use(vueResource)

  export default {
    name: 'app_setting',
    beforeCreate () {
      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.get(config.menu.host + '/food')
        .then(response => {
          loader.hide()
          this.food_list = response.body
          this.food_list.map(function(food){
            food.category = typeof(food.category) == ('string') ? JSON.parse(food.category) : food.category;
            var food_pack = '';
            food.category.map(function(food_cat){
              if(food_pack === ''){
                food_pack = food_cat.category
              }else{
                food_pack = food_pack +', '+ food_cat.category
              }
            })
            food.food_category = food_pack
          })
          this.isshowgrid = true
        }, error => {
          loader.hide()
          if (error && error.body) {
            Vue.notify({ text: error.body, type: 'error' })
          }
        })
    },
    data () {
      return {
        term: null,
        perPage: '10',
        perPageOptions: ['4', '6', '10', '20'],
        food_list: [],
        food_name: '',
        foodNameArr: [],
        FoodNameErrors: [],
        description: '',
        DescriptionErrors: [],
        category: [],
        categoryArr: [{id:1,category:"Breakfast"},{id:2,category:'Brunch'},{id:3,category:'Lunch'},{id:4,category:'Snacks'},{id:5,category:'Beverage'},{id:6,category:'Dinner'}],
        CategoryErrors: [],
        type: '',
        typeArr: ['Veg', 'Non-Veg'],
        TypeErrors: [],
        price: '',
        PriceErrors: [],
        image_file: [],
        previewImage: null, 
        foodVideoName: '', 
        food_video: '',   
        instance_name: {},
        isshowgrid: true,
        isshowForm: false,
        isCreate: false,
        isUpdate: false,
        showRemoveModal: false,
        oldImage: false,
        getImage: false,
        image_url: '',
        videoName: '',
        video_dis_name: '',
        msg: '',
        food_meta_id: '',
        menu_of_the_day: false,
        videoUrl: '',
        imageFile: false,
        listOpen: false,
        menu_of_the_day: false,
        backup_food: '',
        getVideo: false
      };
    },
    computed: {
      formReady () {
        return !this.FoodNameErrors.length && 
               !this.DescriptionErrors.length && 
               !this.CategoryErrors.length && 
               !this.TypeErrors.length && 
               !this.PriceErrors.length 
      },
      CheckFile_upload () {
        if(this.image_file[0]){
          return true;
        }else{
          Vue.notify({ text: 'Please Upload images', type: 'error' });
          return false;
        };
      },
      fields () {
        return [{
          name: 'name',
          title: 'Food Name',
        },
        {
          name: 'food_category',
          title: 'Category',
        },
        {
          name: 'price',
          title: 'Price',
          width: '6%',
        },
        {
          name: '__slot:actions',
          dataClass: 'text-right',
        },
        {
          name: '__slot:action',
          dataClass: 'text-right',
        }]
      },
      filteredData () {
        return search(this.term, this.food_list)
      },
    },
    methods: {
      getFood () {
        const loader = Vue.$loading.show({ width: 40, height: 40 })
        this.$http.get(config.menu.host + '/food')
        .then(response => {
          loader.hide()
          this.food_list = response.body
          this.food_list.map(function(food){
            var food_pack = ''
            food.category.map(function(food_cat){
              if(food_pack === ''){
                food_pack = food_cat.category
              }else{
                food_pack = food_pack + ', ' +food_cat.category
              }
            })
            food.food_category = food_pack
          })
          this.isshowForm = false
          this.isshowgrid = true
        }, error => {
          loader.hide()
          if (error && error.body) {
            Vue.notify({ text: error.body, type: 'error' })
          }
        })
      },
      getInstanceFood(food_name) {
        this.getImage = false 
        if(food_name){
          var filter = this.foodNameArr.filter(function(instant){
            return instant.name == food_name
          })
          var vm = this
          this.getImage = true;
          this.getVideo = true;
          this.description = filter[0].description;
          this.category = filter[0].category;
          this.type = filter[0].type;
          this.price = filter[0].price;
          this.image_url = filter[0].image;
          this.videoName = filter[0].banner_video;
          this.videoUrl = filter[0].banner_video;
          this.food_meta_id = filter[0].food_meta_id;
          this.video_dis_name = this.videoName.split('/').pop();
          this.categoryArr.map(function(item){
            vm.category.map(function(food){
              if(food.category === item.category){
                food.category = item.category
              }
            })
          })
          document.getElementById("myVideo").src = filter[0].banner_video;
        }
      },
      CheckFile (type) {
        this.imageFile = false
        if(this.image_file[0] == undefined || this.image_file.length == 0){
          this.previewImage = null
        }
        if (typeof (type) !== 'undefined') {
          var image_file;
          if(type == 'horizontal'){
            image_file = this.image_file[0] 
          }
          if ((type == 'horizontal') && (image_file !== undefined)) {
            var file_name = image_file.name.split('.').pop()
            if ((file_name.toLowerCase() === 'png' || 'jpg' || 'jpeg')) {
              const img = new Image()
              let width = '500'
              let height = '500'
              this.imageFile = true
              this.image_name = image_file.name
              const reader = new FileReader()
              reader.readAsDataURL(image_file)
              reader.onload = evt => {
                if(type == 'horizontal'){
                  this.previewImage = evt.target.result
                  this.oldImage = false
                  this.getImage = false
                }
                img.onload = () => {
                  if (img.width == width && img.height == height) {
                    if(type == 'horizontal'){
                      this.image_name = image_file.name
                    }
                  } 
                  else {
                    if(type === 'horizontal'){
                      this.image_file = ''
                    }
                    var flag = this.image_file ? 'horizontal' : 'banner'
                    if (flag === 'horizontal') {
                      this.image_file = []
                    }
                    if(this.image_file == ''){
                      this.previewImage = null
                    }
                    Vue.notify({ text: 'Please check the image size', type: 'error' })
                  }
                }
                img.src = evt.target.result
              }
            }else{
              if(type === 'horizontal'){
                this.image_file = ''
                Vue.notify({ text: 'Please check the image Format', type: 'error' })
              }
            }
          }
        }  
      },
      validateVideo (eve, type) {
        var video = eve.target.files[0]
        var size = eve.target.size
        if ((size / (1024 * 1024)) <= 200) {
          if (type == 'food') {
            this.foodVideoName = video.name
            this.food_video = video
            let blobURL = URL.createObjectURL(video);
            document.querySelector("video").style.display = 'block';
            document.querySelector("video").src = blobURL;
          }
          return true
        } else {
          alert('please upload video less than 200 MB')
          this.foodVideoName = ''
          return false
        }
      },
      createFood () {
        this.FoodNameErrors = this.food_name ? [] : ['Food Name is required'];
        this.DescriptionErrors = this.description ? [] : ['Description is required'];
        this.CategoryErrors = ((this.category == '') || (this.category.length == 0)) ? ['Category is required'] : [];
        this.TypeErrors = this.type ? [] : ['Type is required'];
        this.PriceErrors = this.price ? [] : ['Price is required'];

        if(!this.formReady) {
          return
        }
        if(!this.getImage){
          if(!this.CheckFile_upload){ return }          
        }
        if(this.price <= 0){
          return Vue.notify({ text: 'Price should not be zero', type: 'error'});
        }
        var arrName = [];
        this.food_list.map(function(data){arrName.push(data.name)})            
        if(arrName.includes(this.food_name)){
          return Vue.notify({text:'The given food name already exists', type: 'error'});
        }

        var payload = new FormData()
        if(this.getImage){
          payload.append('image', this.image_url)
        }else{
          payload.append('image', this.image_file[0])
          payload.append('image_file_name', this.image_name) 
        }
        if(this.getVideo){
          payload.append('video', this.videoName)
          payload.append('food_meta_id', this.food_meta_id)
        }else{
          payload.append('video',this.food_video);
          payload.append('video_name',this.foodVideoName);
        }
        payload.append('name', this.food_name.trim())
        payload.append('description', this.description)
        payload.append('category', JSON.stringify(this.category))
        payload.append('type', this.type)
        payload.append('price', this.price)
        payload.append('menu_of_the_day', this.menu_of_the_day)
        const loader = Vue.$loading.show({ width: 40, height: 40});
        this.$http.post(config.menu.host + '/food', payload).then(response => {
          loader.hide();
          Vue.notify({ text: response.body, type: 'success' });
          this.getFood();
        },err =>{
          loader.hide();
          if(err && err.body){
            Vue.notify({ text: err.body, type: 'error'});
          };
        });
      },
      updateFood () {
        this.FoodNameErrors = this.food_name ? [] : ['Food Name is required'];
        this.DescriptionErrors = this.description ? [] : ['Description is required'];
        this.TypeErrors = this.type ? [] : ['Type is required'];
        this.PriceErrors = this.price ? [] : ['Price is required'];
        this.CategoryErrors = ((this.category == '') || (this.category.length == 0)) ? ['Category is required'] : [];

        if (!this.formReady) {
          return
        }

        var arrName = [], vm = this;
        this.food_list.map(function(data){
          if(vm.backup_food != data.name){
            arrName.push(data.name)
          };
        });            
        if(arrName.includes(this.food_name)){
          return Vue.notify({text:'The given food name already exists', type: 'error'});
        };   

        var payload = new FormData()
        if(this.image_file[0]){
          payload.append('image',this.image_file[0]);
          payload.append('image_file_name',this.image_name);
        };
        if(this.food_video) {
          payload.append('video',this.food_video);
          payload.append('video_name',this.foodVideoName);
        };
        payload.append('name',this.food_name.trim());
        payload.append('description',this.description);
        payload.append('category',JSON.stringify(this.category));
        payload.append('type',this.type);
        payload.append('price',this.price);
        payload.append('food_id',this.food_id);
        payload.append('menu_of_the_day', this.menu_of_the_day);

        const loader = Vue.$loading.show({ width: 40, height: 40 });
        this.$http.put(config.menu.host + '/food/' + this.food_id, payload).then(responseData => {
          loader.hide();
          Vue.notify({ text: responseData.body, type: 'success' });
          this.getFood();
        }, err => {
          loader.hide();
          if (err && err.body) {
            Vue.notify({ text: err.body, type: 'error' });
          };
        });
      },
      edit (row) {
        this.title = 'Update Food';
        this.food_id = row.food_id;
        this.food_name = row.name;
        this.backup_food = this.food_name;
        this.description = row.description;
        this.category = row.category;
        this.type = row.type;
        this.price = row.price;
        this.FoodNameErrors = [];
        this.DescriptionErrors = [];
        this.CategoryErrors = [];
        this.TypeErrors = [];
        this.PriceErrors = [];
        this.image_file = '';
        this.image_url = row.image;
        this.getImage = row.image ? true : false;
        this.food_video = [];
        this.foodVideoName = '';
        this.previewImage = '';
        this.isshowgrid = false;
        this.isshowForm = true;
        this.isCreate = false;
        this.isUpdate = true;
        this.oldImage = true;
        this.menu_of_the_day = row.menu_of_the_day;
        if(row.video){
          this.getVideo = true;
          this.imageFile = false;
          this.videoUrl = row.video;
          this.video_dis_name = row.video.split('/').pop();
          document.getElementById("myVideo").src = row.video;
        }
      },
      remove (row) {
        this.msg = 'Are you sure to delete the food ' + row.name + ' ?';
        this.entity = row;
        this.showRemoveModal = true;
      },
      cancel () {
        this.showRemoveModal = false;
      },
      deleteFood (data) {
        this.$http.delete(config.menu.host + '/food/' + data.food_id).then(resp => {
          Vue.notify({ text: resp.body, type: 'success' });
          this.list();
        }, err => {
          if (err && err.body) {
            Vue.notify({ text: err.body, type: 'error' });
          };
        });
      },
      add () {
        this.title = 'Create Food'
        this.food_name = '';
        this.FoodNameErrors = [];
        this.description = '';
        this.DescriptionErrors = [];
        this.category = '';
        this.CategoryErrors = [];
        this.type = '';
        this.TypeErrors = [];
        this.price = '';
        this.PriceErrors = [];
        this.food_video = '';
        this.image_file = [];
        this.previewImage = '';
        this.food_term = '';
        this.FoodTermErrors = [];
        this.image_url = '';
        this.food_video = '';
        this.isshowForm = true;
        this.isshowgrid = false;
        this.isUpdate = false;
        this.isCreate = true;
        this.getImage = false;
        this.getVideo = false;
        this.foodVideoName = '';
        this.menu_of_the_day = false;
      },
      list () {
        this.getFood()
        this.isshowgrid = true
        this.isshowForm = false
      },
      search: function (term) {
        this.term = term
      },
      onClick(data) {
        this.food_name = data.name;
        this.listOpen = false;
        this.getInstanceFood(data.name)
      },
      searchfood: _.debounce(function () {
        if(this.food_name.length > 0) {
          var payload = { food_term : this.food_name };
          this.$http.post(config.menu.host + '/food/search', payload).then(response => {
            this.listOpen = true;
            this.foodNameArr = response.body
          });
        } else {
          this.food_name = '';
          this.foodNameArr = [];
          this.listOpen = false;
        }
     }, 400),
    },
  }
</script>
<style scoped>
.results ul {
  width: 50%;
  padding: 6px;
}

.results li {
  background-color: rgba(221, 221, 221, 0.5);
  padding: 5px;
  display: flex;
  align-items: center;
}

.searchbox {
  text-align: left;
}

.searchbox input {
  height: 30px;
  font-size: 16px;
  padding: 0 10px;
}

.results li:hover {
  background-color: rgba(200, 194, 197, 0.8);
  cursor: pointer;
}

.results li span {
  display: inline-block;
}
</style>

